exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1GMbX,._1sQ-b{width:354px;width:35.4rem}._1GMbX{display:-ms-flexbox;display:flex;position:fixed;top:0;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-direction:column;flex-direction:column;-ms-flex:0 0 auto;flex:0 0 auto;background:#1b1b1b;height:100%;padding:3.2rem;-webkit-box-shadow:0 4px 10px rgba(50,66,121,.05);box-shadow:0 4px 10px rgba(50,66,121,.05);z-index:1030;overflow-y:auto}html[dir=ltr] ._1GMbX{border-top-right-radius:16px;border-bottom-right-radius:16px}html[dir=rtl] ._1GMbX{border-top-left-radius:16px;border-bottom-left-radius:16px}", ""]);

// exports
exports.locals = {
	"sidebar": "_1GMbX",
	"wrapperSidebar": "_1sQ-b"
};