exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._33Ibv{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;padding:1.6rem 2.4rem;margin-bottom:3.2rem;width:290px;width:29rem;height:84px;height:8.4rem;-ms-flex:none;flex:none;-ms-flex-order:0;order:0;-ms-flex-item-align:stretch;align-self:stretch;-ms-flex-positive:0;flex-grow:0;background:#101010;border-radius:16px}._1PeC0{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;color:#fff}", ""]);

// exports
exports.locals = {
	"sidebar__headerContainer": "_33Ibv",
	"sidebar__headerContainer__top": "_1PeC0"
};