exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3h0rc{padding:0;overflow-y:auto}._1FL82{color:#fff;font-size:14px;font-size:1.4rem;list-style:none}._1FL82:before{content:\"\";width:290px;width:29rem;height:0;margin-bottom:3.2rem;border-top:1px solid #e6e9e8;display:block;opacity:.1}._2_zhP>a{color:#939393;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;line-height:1.2;margin-top:1.2rem;padding:1.6rem 2.4rem}._2_zhP>a>svg{width:19px;width:1.9rem;height:19px;height:1.9rem;stroke:#939393}.wTGzY{background:hsla(0,0%,100%,.1);font-weight:700}.wTGzY>a,._2_zhP:hover>a{color:#fff;cursor:pointer}.wTGzY>a>svg,._2_zhP:hover>a>svg{fill:#fff}._2OY3R:hover>a>svg{stroke:#fff}._2_zhP{border-radius:16px}._2_zhP:first-child,._2_zhP:last-child{margin-top:0}._2_zhP:last-child:before{content:\"\";width:290px;width:29rem;height:0;margin-top:1.2rem;border-top:1px solid #e6e9e8;display:block;opacity:.1;offset-position:right 3em bottom 10px}._2_zhP:last-child a{margin-top:0}", ""]);

// exports
exports.locals = {
	"sidebar__nav": "_3h0rc",
	"sidebar__nav__list": "_1FL82",
	"sidebar__nav__list__item": "_2_zhP",
	"sidebar__nav__list__item--active": "wTGzY",
	"sidebar__nav__list__item--logout": "_2OY3R"
};