import React, { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { MessageDescriptor } from 'react-intl';
import cn from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Tooltip } from 'react-tooltip';
import { scrollToHash } from 'helpers/urlTools';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import App from 'modules/App';
import messages from '../../messages';
import Sidebar from './Sidebar';
import styles from './DashboardLayout.pcss';


interface Props {
  metaTitleMessage?: MessageDescriptor,
  metaDescriptionMessage?: MessageDescriptor,
}


const DashboardLayout: FC<Props> = ({ metaTitleMessage, metaDescriptionMessage, children }) => {
  const route = useSelector(App.selectors.route);
  const isClientInitialized = useSelector(App.selectors.isClientInitialized);
  const mainContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isClientInitialized) {
      scrollToHash(route, 0, 0, mainContentRef.current);
    }
  }, [isClientInitialized]);

  useStyles(styles);

  if (!isClientInitialized) return null;

  const titleMessage = metaTitleMessage || messages.meta.title;
  const descriptionMessage = metaDescriptionMessage || messages.meta.description;

  const onSetMainContentRef = (el: HTMLDivElement) => {
    mainContentRef.current = el;
  };

  return (
    <App.components.LanguageProvider>
      <div className={cn(styles.root, 'no-gutters')}>
        <App.components.IntlHelmet
          titleMessage={titleMessage}
          descriptionMessage={descriptionMessage}
        />
        <Sidebar />
        <div id="mainContent" className={styles.content} ref={onSetMainContentRef}>
          <App.components.AlertsBus className="mb-5" isGlobal />
          { children }
        </div>
        <UnsavedChangesModal />
        <Tooltip
          id="globalTooltip"
          place="right"
          float
        />
      </div>
    </App.components.LanguageProvider>
  );
};

export default DashboardLayout;
