export default {
  meta: {
    title      : { id: 'layout.meta.title' },
    description: { id: 'layout.meta.description' },
  },
  headers: {
    dashboard           : { id: 'layout.headers.dashboard' },
    approvers           : { id: 'layout.headers.approvers' },
    organizations       : { id: 'layout.headers.organizations' },
    countries           : { id: 'layout.headers.countries' },
    businessLogicSystems: { id: 'layout.headers.businessLogicSystems' },
    publishSnapshot     : { id: 'layout.headers.publishSnapshot' },
    logout              : { id: 'layout.headers.logout' },
  },
  sidebarHeader: {
    adminPanel: { id: 'layout.sidebarHeader.adminPanel' },
    backOffice: { id: 'layout.sidebarHeader.backOffice' },
    env       : {
      dev: { id: 'layout.sidebarHeader.env.dev' },
      tst: { id: 'layout.sidebarHeader.env.test' },
      stg: { id: 'layout.sidebarHeader.env.stg' },
      pre: { id: 'layout.sidebarHeader.env.pre' },
      prd: { id: 'layout.sidebarHeader.env.prd' },
    },
    scope: {
      BackOfficeUser      : { id: 'layout.sidebarHeader.scope.BackOfficeUser' },
      OrganizationApprover: { id: 'layout.sidebarHeader.scope.OrganizationApprover' },
      CountryManager      : { id: 'layout.sidebarHeader.scope.CountryManager' },
      SDNAdmin            : { id: 'layout.sidebarHeader.scope.SDNAdmin' },
    },
  },
};
