exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".GpJe2{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;min-height:100vh;width:100%;overflow-x:hidden}.GpJe2 svg path{vector-effect:non-scaling-stroke}._3g5va{background:none;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;padding-top:3.2rem}._2wHWE{width:64px;margin:0 2rem}._1Wo6g{-ms-flex:1 1 auto;flex:1 1 auto;height:100%}._3R97A{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;height:100%}._3R97A a{font-weight:700}._3R97A .content{padding:3.6rem 2rem 10rem;font-size:14px;font-size:1.4rem}@media (min-width:600px){._3R97A .content{padding:4rem 0 10rem}}@media (min-width:1024px){._3R97A .content{padding:4.8rem 0 10rem}}._3R97A .content--fixed{max-width:none!important}@media (min-width:1024px){._3R97A .content--fixed{width:88rem!important}}._3R97A .pageHeader__intro{font-size:14px;font-size:1.4rem;color:#20353e;line-height:1.5;padding-top:1rem}.content{height:100%}.pageContentBox{width:570px;width:57rem;color:#0d0d0d;background:#fff;border-radius:8px;padding:2.4rem 3.2rem;-webkit-box-shadow:0 4px 10px rgba(50,66,121,.05);box-shadow:0 4px 10px rgba(50,66,121,.05)}.pageContentBox--sm{width:450px;width:45rem}div:first-child>.alert{border-radius:8px}._3v7x0{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._3v7x0,._2hiGa{height:96px;height:9.6rem;background:#fff}._2hiGa{position:fixed;top:0}._1kbjH{width:64px;width:6.4rem}", ""]);

// exports
exports.locals = {
	"root": "GpJe2",
	"logoBar": "_3g5va",
	"logoBar__logo": "_2wHWE",
	"container": "_1Wo6g",
	"containerInner": "_3R97A",
	"logoSidebar": "_3v7x0",
	"logoSidebar__container": "_2hiGa",
	"logoSidebar__logo": "_1kbjH"
};