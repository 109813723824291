exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Sda6{color:#fff;width:40px;width:4rem;height:40px;height:4rem;stroke:#fff;fill:#fff}._1qFS5{overflow-wrap:break-word;overflow:hidden;white-space:nowrap;-o-text-overflow:ellipsis;text-overflow:ellipsis;max-width:240px;max-width:24rem}", ""]);

// exports
exports.locals = {
	"avatarIcon": "_1Sda6",
	"userBox__email": "_1qFS5"
};