import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import HeaderBox from './HeaderBox';
import Nav from './Nav';
import UserBox from './UserBox';
import styles from './Sidebar.pcss';


const Sidebar: FC = () => {
  useStyles(styles);
  return (
    <div className={styles.wrapperSidebar}>
      <aside className={styles.sidebar}>
        <HeaderBox />
        <Nav />
        <UserBox />
      </aside>
    </div>
  );
};

export default Sidebar;
