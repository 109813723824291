import React, { FC, memo, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import history from 'helpers/history';
import { useAction } from 'hooks';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import App from 'modules/App';


const UnsavedChangesModal: FC = () => {
  const isNavigationBlocked = useSelector(App.selectors.isNavigationBlocked);
  const openModalId = useSelector(App.selectors.modal);
  const openModal = useAction(App.actions.openModal, App.constants.UNSAVED_CHANGES_MODAL);
  const onCloseModal = useAction(App.actions.closeModal);
  const unblock = useRef<() => void>();

  useEffect(() => {
    if (isNavigationBlocked) {
      unblock.current = history.block(() => {
        openModal();
        return false;
      });
    } else if (unblock.current) {
      unblock.current();
      unblock.current = undefined;
    }
  }, [isNavigationBlocked]);

  return (
    <Modal
      modalId={App.constants.UNSAVED_CHANGES_MODAL}
      openModalId={openModalId}
      headerMessage={App.messages.headers.unsavedConfiguration}
      styleModifier="md"
      onClose={onCloseModal}
    >
      <p className="text--paragraph mt-8 mb-8 pr-6">
        <FormattedMessage {...App.messages.infos.unsavedConfiguration} />
      </p>
      <Button
        labelMessage={App.messages.buttons.ok}
        className="btn--block btn--primary btn--filled"
        type="button"
        onClick={onCloseModal}
      />
    </Modal>
  );
};

export default memo(UnsavedChangesModal);
