import React, { FC, memo, useContext } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import useStyles from 'isomorphic-style-loader/useStyles';
import { AppContext } from 'context';
import Link from 'components/Link';
import App from 'modules/App';
import Account from 'modules/Account';
import { NAV_ITEMS_ICONS } from '../../../../constants';
import messages from '../../../../messages';
import styles from './Nav.pcss';


interface NavItemProps {
  routing: {
    key: string,
    routeName: string,
  },
}


const useUrlTo = (routing) => {
  const { getUrl } = useContext(AppContext);
  if (!routing.routeName) {
    return '';
  }
  return getUrl(routing.routeName);
};


const NavItem: FC<NavItemProps> = ({ routing }) => {
  const route = useSelector(App.selectors.route);
  const Icon = NAV_ITEMS_ICONS[routing.key];
  const to = useUrlTo(routing);

  return (
    <li
      className={
        cn(styles.sidebar__nav__list__item, styles[`sidebar__nav__list__item--${routing.key}`], {
          [styles['sidebar__nav__list__item--active']]: routing.routeName === route.name,
        })
      }
    >
      <Link to={to}>
        { Icon && <Icon className="mr-5" /> }
        <FormattedMessage {...messages.headers[routing.key]} />
      </Link>
    </li>
  );
};


const Nav: FC = () => {
  const account = useSelector(Account.selectors.account);
  useStyles(styles);
  if (!account) {
    return null;
  }
  const { scope } = account;
  const routings = Account.constants.ROUTING_BY_SCOPE[scope];
  const renderNavItem = (routing) => <NavItem {...{ key: routing.key, routing }} />;
  return (
    <nav className={`col ${styles.sidebar__nav}`}>
      <ul className={styles.sidebar__nav__list}>
        { routings.map(renderNavItem) }
      </ul>
    </nav>
  );
};

export default memo(Nav);
