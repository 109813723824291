import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import useStyles from 'isomorphic-style-loader/useStyles';
import cn from 'classnames';
import AvatarIcon from 'svg/avatar.svg';
import Account from 'modules/Account';
import messages from '../../../../messages';
import styles from './UserBox.pcss';


const UserBox: FC = () => {
  const account = useSelector(Account.selectors.account);
  if (!account) {
    return null;
  }
  const { username, scope } = account;
  const [usernameShortName] = username.split('@');

  useStyles(styles);

  return (
    <div className="col-auto">
      <div className="d-flex mt-7" title={username}>
        <div className="pr-3">
          <AvatarIcon />
        </div>
        <div className="pl-3">
          <p className={cn(styles.userBox__email, 'text--large text--light mb-2')}>{ usernameShortName }</p>
          <p className="text--normal text--small text--disabled">
            <FormattedMessage {...messages.sidebarHeader.scope[scope]} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserBox;
