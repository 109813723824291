exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".vq2nP{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;min-height:100vh;width:100%;overflow-x:hidden}.vq2nP svg path{vector-effect:non-scaling-stroke}._3UDYI{background:none;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;padding-top:3.2rem}._280kN{width:64px;margin:0 2rem}._3UMat{-ms-flex:1 1 auto;flex:1 1 auto;height:100%}.R0OZo{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;height:100%}.R0OZo a{font-weight:700}.R0OZo .content{padding:3.6rem 2rem 10rem;font-size:14px;font-size:1.4rem}@media (min-width:600px){.R0OZo .content{padding:4rem 0 10rem}}@media (min-width:1024px){.R0OZo .content{padding:4.8rem 0 10rem}}@media (min-width:1024px){.R0OZo .content--fixed{width:88rem!important}}.R0OZo .pageHeader__intro{font-size:14px;font-size:1.4rem;color:#20353e;line-height:1.5;padding-top:1rem}.R0OZo .content--fixed{max-width:none!important}@media (min-width:1024px){.R0OZo .content--fixed{width:100rem!important}}@media (min-width:1280px){.R0OZo .content--fixed{width:110rem!important}}", ""]);

// exports
exports.locals = {
	"root": "vq2nP",
	"logoBar": "_3UDYI",
	"logoBar__logo": "_280kN",
	"container": "_3UMat",
	"containerInner": "R0OZo"
};