import React, { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import useStyles from 'isomorphic-style-loader/useStyles';
import cn from 'classnames';
import { AppContext } from 'context';
import Chip from 'components/Chip';
import messages from '../../../../messages';
import styles from './HeaderBox.pcss';


const HeaderBox: FC = () => {
  const { environmentName } = useContext(AppContext);
  useStyles(styles);
  return (
    <div className={`col-auto ${styles.sidebar__headerContainer}`}>
      <div>
        <div className={styles.sidebar__headerContainer__top}>
          <h3 className="text--h3"><FormattedMessage {...messages.sidebarHeader.backOffice} /></h3>
          <Chip labelMessage={messages.sidebarHeader.env[environmentName]} styleModifier="inactive" />
        </div>
      </div>
      <p className={cn('text--small', 'text--disabled')}>
        <FormattedMessage {...messages.sidebarHeader.adminPanel} />
      </p>
    </div>
  );
};

export default HeaderBox;
